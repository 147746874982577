import {useTracking} from '@app/modules/tracking';
import type {Trackables} from '@app/types/tracking';
import type {ButtonProps, LinkProps} from '@tempo/core';
import {forwardRef, Button} from '@tempo/core';
import Link from 'next/link';

export type PropsLandingButtonLink = {
	href: string;
	tracking?: Partial<Trackables>;
	isMain?: boolean;
} & ButtonProps &
	LinkProps;

export const LandingButtonLink = forwardRef<PropsLandingButtonLink, 'button'>(
	({href, tracking, isMain = false, ...props}, ref) => {
		const {trackClick} = useTracking();
		const handleClick = (e: any) => {
			if (tracking) {
				trackClick(tracking)();
			}
			if (props.onClick) {
				props.onClick(e);
			}
		};
		return (
			<Button
				variant={!isMain ? 'outline' : undefined}
				as={Link}
				href={href}
				data-cy="landing-btn-link"
				ref={ref}
				onClick={handleClick}
				{...props}
			/>
		);
	},
);
