import {
	LandingHeading,
	LandingParagraph,
	LandingSection,
	LandingTextGrid,
	LandingTextGridItem,
} from '@tempo/landing';
import {useTranslation} from '@app/hooks/use-translation';
import {SortIcon, DownloadFilledIcon, PlayCircleIcon} from '@tempo/icons';
import {LandingButtonLink} from '@app/components/landing-btn-link';
import {config} from './hero';

export const Content: React.FC<{platform: 'mac' | 'windows' | null}> = ({
	platform,
}) => {
	const {t} = useTranslation({ns: ['legacy']});

	return (
		<LandingSection
			isContained
			description={
				<>
					<LandingHeading as="h2">
						{t('Un nouveau terrain de jeu pour votre musique')}
					</LandingHeading>
					<LandingParagraph>
						{t('desktopapplandingpage_text_newdesktopappavailallusers_web')}{' '}
						{t('desktopapplandingpage_text_windowsXmacosXorlaterneeded_web', {
							sprintf: [7, 10.13],
						})}
					</LandingParagraph>
				</>
			}
			cta={
				platform && (
					<LandingButtonLink
						href={(config.link as Record<string, string>)[platform]}
					>
						{t('generic_action_downloadnow_web')}
					</LandingButtonLink>
				)
			}
		>
			<LandingTextGrid>
				<LandingTextGridItem
					title={t('Raccourcis')}
					icon={<SortIcon size="medium" />}
				>
					{t('Utilisez votre clavier pour contrôler votre musique')}
				</LandingTextGridItem>

				<LandingTextGridItem
					title={t('Mode hors connexion') + '*'}
					icon={<DownloadFilledIcon size="medium" />}
				>
					{t('Téléchargez vos titres favoris')}
				</LandingTextGridItem>

				<LandingTextGridItem
					title={t('Contenus vidéo')}
					icon={<PlayCircleIcon size="medium" />}
				>
					{t('Regardez nos Sessions et interviews')}
				</LandingTextGridItem>
			</LandingTextGrid>
		</LandingSection>
	);
};
