import {LandingSection} from '@tempo/landing';

type Props = {
	description: React.ReactNode;
	image?: React.ReactNode;
	imagePosition?: 'bottom' | 'left' | 'right';
	imageContained?: boolean;
	cta?: React.ReactNode;
};

/**
 * @deprecated use LandingSection instead now this component should be removed asap
 */
export const LandingIllustrationSection: React.FC<Props> = ({
	image,
	description,
	imagePosition = 'bottom',
	imageContained = false,
	cta,
}) => {
	return (
		<LandingSection
			cta={cta}
			contentPosition={imagePosition}
			description={description}
			isContained={imageContained}
		>
			{image}
		</LandingSection>
	);
};
