import {LandingHeading} from '@tempo/landing';
import {useTranslation} from '@app/hooks/use-translation';
import {LandingButtonLink} from '@app/components/landing-btn-link';
import {Image} from '@app/components/Image';
import {useEffect} from 'react';
import {LandingIllustrationSection} from '@app/components/layout/LandingIllustrationSection';

export const config = {
	link: {
		mac: 'https://www.deezer.com/desktop/download?platform=darwin&architecture=x64',
		windows:
			'https://www.deezer.com/desktop/download?platform=win32&architecture=x86',
	},
} as const;

export const DownloadHero: React.FC<{platform: 'mac' | 'windows' | null}> = ({
	platform,
}) => {
	const {t} = useTranslation({ns: ['legacy']});

	useEffect(() => {
		if (platform && location.href.includes('direct_dl')) {
			location.href = config.link[platform];
		}
	}, [platform]);

	return (
		<LandingIllustrationSection
			description={
				<>
					<LandingHeading as="h1">
						{t('desktopappbanner_text_getfulldeezerexperience_web')}
					</LandingHeading>

					{platform && (
						<LandingButtonLink
							href={(config.link as Record<string, string>)[platform]}
							isMain
						>
							{t('generic_action_downloadnow_web')}
						</LandingButtonLink>
					)}
				</>
			}
			image={
				<Image
					h={{base: '200px', md: '500px'}}
					alt={t('metatag_text_musicbasedonyourmood_standalone-web', {
						ns: 'common',
					})}
					quality={90}
					aspectRatio="1440/840"
					src="e2adab65c62df5fce5033f9ba72ab267.png"
					priority
				/>
			}
		/>
	);
};
