import {useState, useEffect} from 'react';
import {osName, OsTypes} from 'react-device-detect';

export const usePlatform = (): ['mac' | 'windows' | null] => {
	const [platform, setPlatform] = useState<'mac' | 'windows' | null>(null);

	useEffect(() => {
		const isMac = osName === OsTypes.MAC_OS;
		const isWindows = osName === OsTypes.Windows;
		const platform = isMac ? 'mac' : isWindows ? 'windows' : null;

		setPlatform(platform);
	}, []);

	return [platform];
};
