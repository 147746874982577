import {
	LandingDescription,
	LandingHeading,
	LandingParagraph,
	LandingSection,
} from '@tempo/landing';
import {useTranslation} from '@app/hooks/use-translation';
import {StoreBadgeGroup, AppleStoreBadge, PlayStoreBadge} from '@tempo/core';
import {useRouter} from 'next/router';

export const Footer: React.FC = () => {
	const {t} = useTranslation({ns: ['legacy']});
	const {locale} = useRouter();

	return (
		<LandingSection isContained>
			<LandingDescription textAlign="center">
				<LandingHeading as="h2">
					{t('Écoutez aussi sur mobile, tablette et plus')}
				</LandingHeading>
				<LandingHeading as="h3">
					{t(
						"Téléchargez l'application mobile ou retrouvez tous les appareils sur lesquels vous pouvez écouter votre musique.",
					)}
				</LandingHeading>
				<StoreBadgeGroup marginInlineStart="0 !important">
					<AppleStoreBadge
						data-cy="app-store"
						target="_blank"
						locale={locale}
					/>
					<PlayStoreBadge
						data-cy="google-play-store"
						target="_blank"
						locale={locale}
					/>
				</StoreBadgeGroup>

				<LandingParagraph>
					{t('desktopapplandingpage_text_onlyavailusersplansX1X2X3X4_web', {
						sprintf: [
							t('deezerplans_title_deezerfamily_web'),
							t('deezerplans_title_deezerstudent_web'),
							t('deezerplans_title_deezerpremium_web'),
							t('deezerplans_title_deezerhifi_web'),
						],
					})}
				</LandingParagraph>
			</LandingDescription>
		</LandingSection>
	);
};
